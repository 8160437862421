import React from "react"
import GridLayout from "./GridLayout"
import PageTransition from "gatsby-plugin-page-transitions"
import BackButton from "../components/BackButton"
import { navigate } from "gatsby"
import { H3, Span } from "../components/styles/TextStyles.styled"
import Avatar from "../components/Avatar"
import SearchContext from "../context/SearchContext"
import ArrowedLink from "../components/Links/ArrowedLink"
import * as S from "./ArticleLayout.styled"

const ArticleLayout = ({
  locale,
  title,
  clientLogo,
  clientName,
  writtenByLabel,
  author,
  children,
  content = [],
  brandsSectionContent,
  tags,
  tagsLabel,
  contact,
  actualPublishDate,
}) => {
  let contentSets = []
  for (let i = 0; i < content.length; i++) {
    if (i === 0 || content[i].breakGrid !== content[i - 1].breakGrid) {
      contentSets.push([content[i]])
    } else {
      contentSets[contentSets.length - 1].push(content[i])
    }
  }

  const authorString = actualPublishDate
    ? `${author?.name}\n${new Date(actualPublishDate).toLocaleDateString()}`
    : author?.name
  return (
    <PageTransition>
      <GridLayout style={{ marginTop: "60px", alignItems: "start" }}>
        <BackButton
          locale={locale}
          onClick={() => navigate(-1)}
          style={{
            marginBottom: !brandsSectionContent && !clientLogo && "85px",
          }}
        />
        <S.Title as="h1">{title}</S.Title>

        <S.ClientLogoContainer>
          {clientLogo && clientLogo.url && (
            <img src={clientLogo.url} alt={clientName} />
          )}
        </S.ClientLogoContainer>

        <S.AuthorSection className="aside">
          <Span className="mono">{writtenByLabel}</Span>
          <Avatar {...author} name={author && authorString} />
        </S.AuthorSection>
        {brandsSectionContent && (
          <S.BrandsSection>{brandsSectionContent}</S.BrandsSection>
        )}
        {contentSets.length > 0 && !contentSets[0][0].breakGrid && (
          <S.ContentContainer>
            {contentSets[0].map(({ node }, i) => (
              <React.Fragment key={i}>{node}</React.Fragment>
            ))}
          </S.ContentContainer>
        )}
      </GridLayout>
      {contentSets.length > 0 &&
        contentSets.map((c, i) => {
          if (i === 0 && !c[0].breakGrid) return <React.Fragment key={i} />
          const Container = c[0].breakGrid ? React.Fragment : GridLayout
          return (
            <Container key={i}>
              <S.ContentContainer>
                {contentSets[i].map(({ node }, i) => (
                  <React.Fragment key={i}>{node}</React.Fragment>
                ))}
              </S.ContentContainer>
            </Container>
          )
        })}

      <S.FooterSection>
        {tags?.length > 0 && tagsLabel && (
          <SearchContext.Consumer>
            {({ search }) => (
              <S.TagsSection
                using={tags}
                title={tagsLabel}
                onTagClick={t => search(locale, [t.originalId], null)}
              />
            )}
          </SearchContext.Consumer>
        )}
        {contact && (
          <>
            <H3>{contact.title}</H3>
            <ArrowedLink
              link={{
                label: contact.linkLabel,
                url: `mailto:${contact.email}`,
                external: true,
              }}
            />
            <Span css={{ display: "block" }}>
              {contact.pleaseContactLabel} <u>{contact.name}</u>.<br />
              {contact.role}
              <br />
              <br />
              <a href={`mailto:${contact.email}`}>{contact.email}</a>
              {contact.phoneNumber && (
                <>
                  <br />
                  <a href={`tel:${contact.phoneNumber}`}>
                    {contact.phoneNumber}
                  </a>
                </>
              )}
            </Span>
          </>
        )}
      </S.FooterSection>
      {children}
    </PageTransition>
  )
}

export default ArticleLayout
