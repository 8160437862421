import styled from "styled-components"
import { ArticleTitle } from "../components/styles/TextStyles.styled"
import device from "../theme/devices"
import GridLayout from "./GridLayout"
import TagSet from "../components/TagSet"

export const Title = styled(ArticleTitle)`
  grid-row: 2;
  grid-column: 1/ 7;
  margin: 12px 0;
  @media (${device.desktop}) {
    grid-row: 2;
    grid-column: 4 /9;
    margin: 0;
  }
`

export const ClientLogoContainer = styled.div`
  grid-row: 1;
  grid-column: 3 / 7;
  @media (${device.desktop}) {
    grid-row: 1;
    grid-column: 9 / 11;
    min-height: 45px;
  }

  img {
    max-width: 100%;
  }
`

export const Aside = styled.aside`
  span {
    font-size: 13px;
    line-height: 18px;
  }

  .avatar span {
    font-weight: 300;
  }
`

export const ContentContainer = styled.div`
  grid-row: 5;
  grid-column: 1 / 7;
  display: flex;
  flex-direction: column;
  @media (${device.desktop}) {
    grid-row: 3;
    grid-column: 4 / 11;
  }

  .cmsTextHtml {
    font-size: 18px;
    line-height: 27px;
    font-weight: 300;

    ol {
      margin-left: 0;

      li::marker {
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
      }
    }

    b,
    strong {
      font-weight: 500;
    }
    img {
      max-width: 100%;
    }
  }
`

export const AuthorSection = styled(Aside)`
  grid-row: 3;
  grid-column: 1 / 7;
  @media (${device.desktop}) {
    grid-row: 2;
    grid-column: 2 / 4;
  }

  .avatar {
    margin-top: 5px;
  }
`

export const BrandsSection = styled(Aside)`
  grid-row: 4;
  grid-column: 1 / 7;
  padding-top: 10px;
  @media (${device.desktop}) {
    grid-row: 3;
    grid-column: 2 / 4;
    padding-top: 20px;
    display: block;
  }

  .brand {
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    display: block;
  }

  .sdg-label {
    margin-top: 15px;
    display: block;
    @media (${device.desktop}) {
      margin-top: 50px;
    }
  }

  img.sdg {
    margin-top: 16px;
    max-width: 56px;
    max-height: 56px;
    margin-right: 14px;
  }
`

export const FooterSection = styled(GridLayout)`
  h3 {
    grid-column: 1 / 5;
    grid-row: 2;
    margin-top: 30px;
  }
  .link-wrapper {
    grid-row: 2;
    grid-column: 5 / 7;
    margin-top: 30px;
    justify-self: end;
  }

  span {
    grid-row: 3;
    grid-column: 1 / 7;
  }

  a {
    color: inherit;
  }
  @media (${device.desktop}) {
    h3 {
      grid-column: 8 / 11;
      grid-row: 2;
      margin-top: 0;
    }

    .link-wrapper {
      grid-row: 2;
      grid-column: 11 / 12;
      margin-top: 0;
      justify-self: auto;
    }

    h3,
    .link-wrapper {
      margin-top: 120px;
    }

    span {
      grid-row: 3;
      grid-column: 8 / 11;
    }
  }
`

export const TagsSection = styled(TagSet)`
  margin-top: 45px;
  grid-row: 1;
  grid-column: 1 / 7;
  @media (${device.desktop}) {
    grid-column: 4 / 7;
  }
`
