import styled from "styled-components"

export const AdditionalContentContainer = styled.div`
  margin-top: 35px;
  p:first-of-type {
    margin-top: 0;
  }

  .content {
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;

    a {
      color: ${props => props.theme.color};
    }
  }
`
