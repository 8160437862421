import React from "react"
import DatoCmsText from "../DatoCmsText/DatoCmsText"
import * as S from "./styled"
import FileDownloadLink from "../FileDownloadLink"
import Img from "gatsby-image"
import { Span } from "../styles/TextStyles.styled"
import Carousel from "../Carousel"
import ArrowedLink from "../Links/ArrowedLink"

const DatoCmsArticleContent = ({ __typename, ...props }) => {
  switch (__typename) {
    case "DatoCmsCallToActionBlock":
      return (
        <ArrowedLink
          ctaData={props.cta}
          style={{ marginBottom: 20, marginTop: 20 }}
        />
      )
    case "DatoCmsText":
      return <DatoCmsText {...props} />
    case "DatoCmsAdditionalContent":
      return (
        <S.AdditionalContentContainer>
          <span>{props.title}</span>
          <DatoCmsText {...props} className="content" />
        </S.AdditionalContentContainer>
      )
    case "DatoCmsDownload":
      return <FileDownloadLink css={{ margin: "55px 0" }} {...props.file} />
    case "DatoCmsImage":
      return (
        <>
          <Img fluid={props.image.fluid} alt={props.image.alt} />
          {props.captionVisible && props.image.title && (
            <Span
              className="mono"
              css={{ marginLeft: "auto", marginBottom: "30px" }}
            >
              {props.image.title}
            </Span>
          )}
        </>
      )
    case "DatoCmsSlideshow":
      return (
        <Carousel
          type="slides"
          items={props.images.map(m => ({
            fluid: m.fluid,
          }))}
        />
      )
    default:
      return <div>not implemented yet content type {__typename}</div>
  }
}

export default DatoCmsArticleContent
