import React from "react"
import styled from "styled-components"
import { ReactComponent as Arrow } from "../../assets/arrow-right.svg"
import { Span } from "../styles/TextStyles.styled"
import GridLayout from "../../layouts/GridLayout"
import device from "../../theme/devices"

const Container = styled.a`
  padding: 18px 20px;
  background: #fdfeff;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  display: block;
  text-decoration: none;
  outline: none;

  :hover {
    text-decoration: none;
    cursor: pointer;
  }
`

const GridContainer = styled.div`
  grid-row: 1;
  grid-column: 1 / 7;

  @media (${device.desktop}) {
    grid-column: 4 / 10;
  }
`

const FileDownloadLink = ({ url, title, filename, ...props }) => (
  <Container href={url} download {...props}>
    <Arrow style={{ transform: "rotate(90deg" }} />
    <Span css={{ fontWeight: "500", marginLeft: "12px" }}>
      Download {title || filename}
    </Span>
  </Container>
)

export default FileDownloadLink

export const WrappedFileDownloadLink = props => (
  <GridLayout>
    <GridContainer>
      <FileDownloadLink {...props} />
    </GridContainer>
  </GridLayout>
)
