import React from "react"
import { graphql } from "gatsby"
import ArticleLayout from "../layouts/ArticleLayout"
import DatoCmsArticleContent from "../components/DatoCmsArticleContent"
import { HelmetDatoCms } from "gatsby-source-datocms"

export default ({ data: { person, labels }, pageContext: { locale } }) => {
  return (
    <ArticleLayout
      title={person.name}
      locale={locale}
      content={person.content.map(c => ({
        node: <DatoCmsArticleContent {...c} />,
      }))}
      contact={{
        ...person,
        linkLabel: labels.contact,
        pleaseContactLabel: labels.pleaseContactTeamMember,
        title: labels.knowMoreAboutTeamMember,
      }}
    >
      <HelmetDatoCms seo={person.seoMetaTags} />
    </ArticleLayout>
  )
}

export const query = graphql`
  query($id: String!, $locale: String!) {
    labels: datoCmsTranslation(locale: { eq: $locale }) {
      knowMoreAboutTeamMember
      pleaseContactTeamMember
      contact
    }
    person: datoCmsTeamMember(id: { eq: $id }, locale: { eq: $locale }) {
      name
      phoneNumber
      role
      email
      content {
        ... on DatoCmsText {
          __typename
          id
          text
          textNode {
            internal {
              mediaType
            }
          }
        }
        ... on DatoCmsImage {
          id
          captionVisible
          image {
            alt
            title
            fluid(maxWidth: 900, imgixParams: { fm: "jpg", auto: "compress" }) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
        ... on DatoCmsAdditionalContent {
          id
          title
          text
          textNode {
            internal {
              mediaType
            }
          }
        }
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`
